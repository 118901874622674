<template>
  <NuxtLink :to="sponsorUrl">
    <NuxtImg
      :src="sponsor.coverUrl"
      v-bind="$attrs"
      loading="lazy"
      class="w-full"
    />
  </NuxtLink>
</template>

<script>
  export default {
    name: "SponsorCard",
  }
</script>

<script setup>
  const props = defineProps(["sponsor"])
  const { inMembersArea } = useMembersArea()
  const linkPrefix = inMembersArea.value ? "/members" : ""
  const sponsorUrl = `${linkPrefix}/series/${props.sponsor.cachedSlug}`
</script>
